/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CSSProperties } from "react";
import { colorTheme } from "../../../styles";

export const PSHorizontalBarChartStyle = {
    self: (height: number) => css`
        width: 100%;
        height: ${height}px;
    `,
};

export const tooltipStyles: Record<string, CSSProperties> = {
    tooltipContainer: {
        background: colorTheme.blue[''],
        padding: '10px',
        borderRadius: '5px',
        position: 'relative',
        color: colorTheme['white'][''],
    },
    tooltipArrow: {
        zIndex: -10,
        width: '12px',
        height:'12px',
        backgroundColor: colorTheme.blue[''],
        position: 'absolute',
        left: 32,
        top: '120%',
        transform: "translateY(-50%) rotate(45deg)"
    }
}