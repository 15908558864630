/** @jsxImportSource @emotion/react */
import React, { FC, useRef, useEffect, useMemo } from "react";
import * as echarts from 'echarts';
import { PSHorizontalBarChartStyle, tooltipStyles } from './PSHorizontalBarChart.css';
import { colorTheme } from "../../../styles";
import { EChartOption } from "echarts";
import ReactDOMServer from "react-dom/server";
import { ViolationTypesResponse } from "../../../gql/generated/graphql";
import { formatNumberWithCommasAndMaxValue } from "../../../utils";

export type DataObjectValue = ViolationTypesResponse & {color?: string}

type IProps = {
    height: number;
    isLoading?: boolean;
    data?:  Record<string, DataObjectValue[]>;
    hideEmptyCategories?: boolean;
    barWidth?: number;
    minDisplayedLengthPercentage?: number;
    tooltipFormatter?: (params: echarts.EChartOption.Tooltip.Format) => string;
    tooltip?: Omit<echarts.EChartOption.Tooltip, 'formatter'>;
}

const defaultTooltipFormatter = (params: EChartOption.Tooltip.Format, itemToValueMap: Record<string, DataObjectValue> | undefined) => {
    const { seriesName } = params;

    let tooltipValue = seriesName;

    if (seriesName && itemToValueMap) {
        const seriesValue = itemToValueMap[seriesName].value;
        if (seriesValue) tooltipValue = `${seriesName} (${formatNumberWithCommasAndMaxValue(seriesValue)})`;
    }

    const Markup = (
        <div style={tooltipStyles.tooltipContainer}>
            <div style={tooltipStyles.tooltipArrow}/>
            {tooltipValue}
        </div>
    )
    return ReactDOMServer.renderToStaticMarkup(Markup)
};

const estimateLongestStringWidth = (strings?: string[]) => {
    if (!strings) return 0;
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (!context) return 0;

    context.font = '12px sans-serif';

    const maxWidth = strings.reduce((acc, curr) => {
        const width = context.measureText(curr).width;
        return Math.max(acc, width);
    }, 0);

    canvas.remove && canvas.remove();
    return maxWidth;
}

const PSHorizontalBarChart: FC<IProps> = (props) => {
    const {
        height,
        barWidth = 20,
        minDisplayedLengthPercentage = 3,
        isLoading = false,
        hideEmptyCategories = true,
        data = {},
        tooltipFormatter = defaultTooltipFormatter,
        tooltip = {},
    } = props;

    const chartRef = useRef<HTMLDivElement | null>(null);

    const { categories, series, itemToValueMap } = useMemo(() => {
        if (!data) return {};

        const dataObjectToUse = hideEmptyCategories ?
            Object.fromEntries(
            Object.entries(data).filter(([, arr]) => Array.isArray(arr) && arr.length > 0)
        ) : data

        const categories = Object.keys(dataObjectToUse);

        const itemToValueMap: Record<string, DataObjectValue> = {};

        const flatItems = categories.flatMap(category => data[category]);

        const { allViolationNames, maxValue } = flatItems.reduce((acc, curr) => {
            itemToValueMap[curr.name] = curr;

            acc.allViolationNames.push(curr.name);
            acc.maxValue = Math.max(acc.maxValue, curr.value || 0);
            return acc;
        }, { allViolationNames: [] as string[], maxValue: 0 });

        const lastViolationNames = categories.reduce((acc, curr) => {
            const violationsArray = data[curr];
            if (violationsArray.length) {
                const lastViolation = violationsArray[violationsArray.length - 1];
                acc.push(lastViolation.name);
            }
            return acc;
        }, [] as string[]);

        const minSeriesValue = (minDisplayedLengthPercentage / 100) * maxValue;

        const series = allViolationNames.reduce((acc, name) => {
            const seriesInfo = itemToValueMap[name];
            const isLastInBar = lastViolationNames.includes(name);

            const seriesData = categories.map(category => {
                const violationData = data[category].find(item => item.name === name);
                return (violationData && violationData.value) ? Math.max(violationData.value, minSeriesValue) : '-';
            });

            acc.push({
                name,
                type: 'bar',
                stack: 'total',
                label: {
                    color: colorTheme['black'][50],
                    backgroundColor: colorTheme['white'][''],
                    show: true,
                    position: 'top',
                    formatter: () => name,
                },
                labelLayout: (params: any) => {
                    if (params.labelRect.width * 1.1 > params.rect.width) return { width: 0, fontSize: 0 };
                },
                barWidth: barWidth,
                itemStyle: {
                    borderWidth: 2,
                    borderColor: colorTheme['white'][''],
                    color: seriesInfo?.color || colorTheme['blue'][30],
                    borderRadius: isLastInBar ? [0, 20, 20, 0] : undefined,
                },
                emphasis: { // @ts-ignore
                    focus: 'series'
                },
                blur: {
                    itemStyle: {
                        color: colorTheme['blue'][20],
                        opacity: 0.8,
                    }
                },
                data: seriesData,
            });
            return acc;
        }, [] as echarts.EChartOption.SeriesBar[]);

        return {
            categories,
            series,
            itemToValueMap
        };
    }, [data,barWidth, hideEmptyCategories, minDisplayedLengthPercentage]);

    const yAxisOffset = useMemo(() => estimateLongestStringWidth(categories), [categories])

    useEffect(() => {
        if (!chartRef.current) return;
        const chartInstance = echarts.init(chartRef.current);

        const loadingSeries = {
            name: 'loading',
            type: 'bar',
            stack: 'loading',
            label: {
                show: false,
            },
            barWidth: 20,
            barMinHeight: 300,
            itemStyle: {
                color: colorTheme['black'][30],
                borderRadius: [0, 20, 20, 0],
            },
            data: [1, 5, 10, 8],
        }

        const options = {
            tooltip: {
                trigger: 'item',
                formatter: (params: any) => tooltipFormatter(params, itemToValueMap),
                backgroundColor: colorTheme['blue'][''],
                borderWidth: 0,
                show: !isLoading,
                position: function (point: number[]) {
                    return [(point[0] as number) - 50, (point[1] as number) - 75];
                },
                ...tooltip,
            },
            cursor: 'auto',
            legend: {
                show: false,
            },
            grid: {
                left: 0,
                right: 25,
                bottom: 0,
                top: 0,
                containLabel: true
            },
            xAxis: {
                show: !isLoading,
                type: 'value',
                axisLabel: {
                    color: colorTheme['black'][40],
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: colorTheme['black'][30],
                        type: 'dashed',
                    }
                },
            },
            yAxis: {
                type: 'category',
                data: categories,
                inverse: true,
                axisLine: {
                    show: !isLoading,
                    lineStyle: {
                        color: colorTheme['black'][20],
                        width: 2,
                        type: 'solid'
                    }
                },
                axisTick: {
                    show: false,
                },
                z: 10,
                offset: yAxisOffset,
                axisLabel: {
                    show: !isLoading,
                    align: 'left',
                    margin: 20,
                    color: colorTheme['black'][''],
                },
            },
            series: isLoading ? loadingSeries : series,
        } as echarts.EChartOption| echarts.EChartsResponsiveOption;

        chartInstance.setOption(options);

        const handleResize = () => {
            chartInstance.resize();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            chartInstance.dispose();
        };
    }, [data, isLoading, categories, itemToValueMap, series, tooltip, tooltipFormatter, yAxisOffset]);

    return <div ref={chartRef} css={PSHorizontalBarChartStyle.self(height)} />;
}

export default PSHorizontalBarChart;