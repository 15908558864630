import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import {Application} from "../../gql/generated/graphql";

interface HGAState {
    currentConnectorName: string;
    setCurrentConnectorName: (newName: string) => void;
    getCurrentConnectorName: () => string;

    homegrownAppConnectors: Partial<Application>[];
    setHomegrownAppConnectors: (list: Partial<Application>[]) => void;

    lastHomegrownApp: string | undefined;
    setLastHomegrownApp: (value: string) => void;

    currentHGAID: string | undefined;
    setCurrentHGAID: (value: string) => void;
}

const useHGAStore = create<HGAState>()(
    persist(
        (set, get) => ({
            currentConnectorName: '',
            setCurrentConnectorName: (newName) => set(() => ({ currentConnectorName: newName })),
            getCurrentConnectorName: () => get().currentConnectorName,

            homegrownAppConnectors: [],
            setHomegrownAppConnectors: (newList) => set(() => ({ homegrownAppConnectors: newList })),

            lastHomegrownApp: '',
            setLastHomegrownApp: (newApp) => set(() => ({ lastHomegrownApp: newApp })),

            currentHGAID: '',
            setCurrentHGAID: (newID) => set(() => ({ currentHGAID: newID })),
        }),
        {
            name: 'hga-store',
            partialize: (state) => ({
                lastHomegrownApp: state.lastHomegrownApp,
                currentHGAID: state.currentHGAID,
            }),
        }
    )
)

export default useHGAStore;