export const DOMAIN_REGEX = /^(?=.{1,353}$)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{2,}$/;
export const URL_REGEX = /^(?=.{1,353}$)(https?:\/\/)?([a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*\.[a-zA-Z]{2,})(\/[^\s]*)?$/;

export const camelToKebabCase = (str: string): string => {
  return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase();
};

export const camelCaseToTitleCase = (input?: string) => {
    if (!input) return '';

    return input
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/^\w/, (c) => c.toUpperCase())
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

export const capitalizeFirstLetterAndLowerCaseRest = (str: string): string => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const emailToInitials = (email: string) => {
  return email.split("@")[0].split(".").join(". ").toLowerCase();
};

export const nameToUpperCase = (name: string) => {
  return name
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const censorStringEnd = (str: string, end?: number) => {
  const censor = "*";
  const strLength = end || str.length;
  return str.substring(0, str.length - strLength) + censor.repeat(strLength);
}

export const formatNumberWithCommas = (value: number | string) => {
    if (!Number(value) && Number(value) !== 0) return ''
    let localValue = typeof value === "string" ? value : value.toString();
    return localValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatNumberWithCommasAndMaxValue = (number?: number, maxNumericValue = 99999) => {
    if (typeof number !== 'number') return '';
    return number > maxNumericValue ? `${formatNumberWithCommas(maxNumericValue)}+` : formatNumberWithCommas(number);
}

export const snakeCaseWithAllCapsToSentenceCase = (input: string) => {
  if (!input) return ''

  const interimString = input
      .toLowerCase()
      .replaceAll('_', ' ')

  return interimString.charAt(0).toUpperCase() + interimString.slice(1);
}

export const pluralizeIfNeeded = (word: string, count: number, pluralForm?: string): string => {
    if (count === 1) return word;
    return pluralForm ?? `${word}s`;
};

export const isString = (value: unknown): value is string => {
    return typeof value === 'string';
};