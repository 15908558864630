/** @jsxImportSource @emotion/react */
import React from 'react';
import { graphql } from '../gql';
import { useGraphQL } from '../hooks';
import { PSLoadingScreen } from '../ui-kit';
import { datadogRum } from '@datadog/browser-rum';

const initialLoaderQuery = graphql(`
    query initialLoaderQuery {
        queryOpensearchDataRetentionDate {
            date
        }
        getOnboardingSecurityApproachStatus
        getEnvironmentSettings {
            datadog {
                enabled
                clientMonitor {
                    applicationId
                    clientToken
                    env
                }
            }
            platformVersion
        }
    }
`)

type IProps = {
    children: React.ReactNode;
};

const InitialLoader: React.FC<IProps> = (props) => {
    const { children } = props;

    const {data} = useGraphQL({
        document: initialLoaderQuery,
        onSuccess(data) {
            localStorage.setItem('dataRetention', data.queryOpensearchDataRetentionDate?.date);
            if (data.getOnboardingSecurityApproachStatus) localStorage.setItem('onboardedSecurityApproach', data.getOnboardingSecurityApproachStatus);
            if (data.getEnvironmentSettings?.datadog?.enabled) {
                try {
                    datadogRum.init({
                        applicationId: data.getEnvironmentSettings.datadog.clientMonitor?.applicationId!,
                        clientToken: data.getEnvironmentSettings.datadog.clientMonitor?.clientToken!,
                        site: 'datadoghq.com',
                        service: 'rum',
                        version: data.getEnvironmentSettings.platformVersion,
                        env: data.getEnvironmentSettings.datadog.clientMonitor?.env!,
                        sessionSampleRate: 100,
                        sessionReplaySampleRate: 100,
                        defaultPrivacyLevel: 'mask',
                        trackResources: true,
                        trackLongTasks: true,
                        trackUserInteractions: true,
                        enablePrivacyForActionName: true
                    });
                } catch (error) {
                    console.warn('Error initializing Datadog Client');
                }
            }
        },
    });

    if (!data) return <PSLoadingScreen />;

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default InitialLoader;