import { create } from 'zustand';

interface ClientConfigurationState {
    isIDPConnected: boolean;
    setIsIDPConnected: (value: boolean) => void;

    isLoggingDisabled: boolean;
    setIsLoggingDisabled: (value: boolean) => void;
}

const useClientConfigurationsStore = create<ClientConfigurationState>((set) => ({
    isIDPConnected: false,
    setIsIDPConnected: (newValue) => set(() => ({ isIDPConnected: newValue })),

    isLoggingDisabled: false,
    setIsLoggingDisabled: (newValue) => set(() => ({ isLoggingDisabled: newValue })),
}));

export default useClientConfigurationsStore;