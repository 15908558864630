export type ColorTheme = typeof colorTheme;

type ShadeKeys<T> = T extends Record<string, any> ? keyof T : never;

export type ColorThemeKeys = {
  [Color in keyof ColorTheme]: {
    [Shade in ShadeKeys<ColorTheme[Color]>]: Shade extends ""
    ? Color
    : `${Color}-${Shade}`;
  }[ShadeKeys<ColorTheme[Color]>];
}[keyof ColorTheme];

export const colorTheme = {
  gradient: {
    purple: 'linear-gradient(180deg, #412884 -66.77%, #8750E3 85.81%)'
  },
  graph: {
    blue: "#8AC6FF",
    green: "#8CE5C3",
    orange: "#FACD8C",
    red: "#FF9A9A",
    purple: "#BC8CFA",
  },
  transparent: {
    "": "transparent",
  },
  white: {
    '': "#FFFFFF",
    20: "#FFFFFF33",
    15: "#FFFFFF26",
    10: "#FFFFFF1A",
    5: "#FFFFFF0D",
  },
  black: {
    '': '#1C1D22',
    80: '#323435',
    70: '#5D5D5D',
    60: '#737582',
    50: '#939598',
    40: '#C0C0C2',
    30: '#DDDEE3',
    20: '#E8E8E9',
    10: '#F2F2F2',
    5: '#FAFAFB',
  },
  blue: {
    '': "#040F2D",
    80: "#0C1A40",
    70: "#344CCC",
    60: "#415FFF",
    50: "#677FFF",
    40: "#67B8FF",
    30: "#A3B1FF",
    20: "#CFD7FF",
    10: "#E9F2FF",
    5: "#F5F7FF",
  },
  green: {
    '': "#00645A",
    80: "#078374",
    70: "#1AAC5C",
    60: "#32A277",
    50: "#3FCB95",
    40: "#84E0B6",
    30: "#8DFA89",
    20: "#C6FFCF",
    10: "#ECFAF4",
    5: "#F5FCFA",
  },
  aqua: {
    '': "#014272",
    80: "#187E96",
    70: "#1FB8DB",
    60: "#00ABF3",
    50: "#55C6FF",
    40: "#43E4FF",
    30: "#9EE0FA",
    20: "#7CF5FC",
    10: "#B8F5F8",
    5: "#EEFEFF",
  },
  orange: {
    '': "#6B5217",
    80: "#946D2B",
    70: "#C06F00",
    60: "#E57828",
    50: "#FFA302",
    40: "#FFB535",
    30: "#FFD15C",
    20: "#FAE3A7",
    10: "#FFF6E6",
    5: "#FFFAF2",
  },
  red: {
    '': "#891E46",
    80: "#BD275F",
    70: "#E5004C",
    60: "#FF4B63",
    50: "#EC3177",
    40: "#F05A92",
    30: "#F37787",
    20: "#FFA0AD",
    10: "#FDEAF1",
    5: "#FEF5F8",
  },
  purple: {
    '': "#681DA7",
    80: "#6947CF",
    70: "#9238D9",
    60: "#A569E7",
    50: "#B56FED",
    40: "#BC8CFA",
    30: "#D5ADFD",
    20: "#E4C7FD",
    10: "#F3DDFD",
    5: "#FAF1FE",
  },
  yellow: {
    '': "#687B09",
    80: "#9AB709",
    70: "#CFD22A",
    60: "#DBE599",
    50: "#FCDB1F",
    40: "#FFF200",
    30: "#FFFD94",
    20: "#F0FE94",
    10: "#FEFEB9",
    5: "#FFFFE3",
  }
} as const;

export type BreakpointKeys = keyof typeof breakpoints;
export const breakpoints = {
  sm: 0,
  md: 1367,
  lg: 1601,
  xl: 1920,
} as const;

export const mediaQueries = (Object.keys(breakpoints) as BreakpointKeys[]).reduce((acc, key) => {
  acc[key] = `@media (min-width: ${breakpoints[key]}px)`;
  return acc;
}, {} as Record<BreakpointKeys, string>);

export type SpacingKeys = keyof typeof spacing;
export const spacing = {
  xxs: '1px',
  xs: '5px',
  sm: '10px',
  md: '15px',
  lg: '20px',
  xl: '25px',
  xxl: '30px',
} as const;

export type RadiusKeys = keyof typeof radius;
export const radius = {
  xxs: '5px',
  xs: '10px',
  sm: '15px',
  md: '20px',
  lg: '30px',
  xl: '40px',
  xxl: '50px',
} as const;

export type OuterElevationKeys = keyof typeof outerElevation;
export const outerElevation = {
  1: "0px 4px 4px 0px rgba(0, 0, 0, 0.04)",
  2: "0px 2px 6px 0px rgba(0, 0, 0, 0.10)",
  3: "0px 6px 10px 0px rgba(0, 0, 0, 0.10)",
  4: "0px 12px 24px 0px rgba(0, 0, 0, 0.12)",
} as const;

export type InnerElevationKeys = keyof typeof innerElevation;
export const innerElevation = {
  1: "5px 0px 10px 0px rgba(0, 0, 0, 0.08) inset"
} as const;

export type PromptSecurityTheme = typeof promptSecurityTheme;
export const promptSecurityTheme = {
  color: colorTheme,
  breakpoints,
  spacing,
  radius,
  outerElevation,
  innerElevation,
} as const;