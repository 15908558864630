/** @jsxImportSource @emotion/react */
import React, {FC, ReactNode} from 'react';
import { PSOverviewStyle } from "./PSOverview.css";
import { TOverviewVariants } from "./types";
import { Icon, Circle, PSEmptyState, PSPopper } from '../index'
import { PSIconNames } from "../Icon/Icon";
import { Text } from '../../ui-kit'
import { Skeleton } from "@mui/material";
import { formatNumberWithCommasAndMaxValue } from '../../utils'

type IProps = {
    variant: TOverviewVariants;
    iconName: PSIconNames;
    textContent: string | ReactNode;
    popperContent?: string;
    numberContent?: number;
    isLoading?: boolean;
    isError?: boolean;
    errorOnClick?: () => void;
    topBadge?: ReactNode;
    badge?: ReactNode;
    footer?: ReactNode;
    minWidth?: number;
    maxWidth?: number;
    maxContentWidth?: number;
}

const PSOverview: FC<IProps> = (props) => {
    const { variant, iconName, textContent, numberContent, popperContent, isLoading, badge, topBadge, footer, minWidth, maxWidth, maxContentWidth, isError = false, errorOnClick } = props;

    const displayedNumber = formatNumberWithCommasAndMaxValue(numberContent);

    const styles = PSOverviewStyle(variant);

    if (isError) return (
        <div css={styles.errorContainer}>
            <PSEmptyState
                variant='error'
                title='Something went wrong'
                size='small'
                mode='horizontal'
                description={<Text variant='text' color='black-70' onClick={errorOnClick} showUnderlineOnClick>Click here to try again</Text>}
            />
        </div>
    )

    return (
        <div css={styles.self(minWidth, maxWidth)}>
            <style>
                {`
                    .number {
                       font-size: 45px;
                    }
                
                    @container (max-width: 370px){
                         .OverviewIcon {
                             width: 50px;
                             height: 50px;
                             svg {
                                width: 30px;
                                height: 30px;
                             }
                         }
                         
                         .textWrapper {
                            justify-content: center
                         }
                         
                         .number {
                            font-size: 40px;
                            line-height: 35px;
                         }
                         
                         .title {
                            font-size: 15px;
                         }
                    }
                    
                    @container (max-width: 340px){
                         .OverviewIcon {
                             width: 50px;
                             height: 50px;
                             svg {
                                width: 30px;
                                height: 30px;
                             }
                         }
                         
                         .number {
                            font-size: 35px;
                            line-height: 30px;
                         }
                    }
                    
                    @container (max-width: 315px){
                         .OverviewIcon {
                            display: none; 
                         }
                         
                         .contentWrapper {
                            gap: 0;
                         }
                         
                        .badgeContainer {
                            justify-self: end;
                            margin-left: auto;
                        }
                    }
                `}
            </style>
            <Circle color={'red-50'} size={215} circleCss={styles.glow}/>

            <div css={styles.topContainer}>
                <div css={styles.contentWrapper(maxContentWidth)} className='contentWrapper'>
                    <div css={styles.iconWrapper}>
                        <div css={styles.iconContainer} className='OverviewIcon'>
                            <Icon iconSize={35} iconName={iconName} color={variant === 'dark' ? 'purple-80' : 'purple-50'} />
                        </div>
                    </div>

                    <div css={styles.contentTextContainer} className='textWrapper'>
                        {isLoading ?
                            (<div css={styles.skeletonContainer}>
                                <Skeleton variant='text' animation='pulse' height={20} width={100} className='loading-skeleton' css={styles.skeleton}/>
                                 <div css={styles.numberSkeletonContainer}>
                                    <Skeleton variant='text' animation='pulse' height={20} width={100} className='loading-skeleton' css={styles.skeleton}/>
                                     <Skeleton variant='text' animation='pulse' height={20} width={65} className='loading-skeleton' css={styles.skeleton}/>
                                 </div>
                            </div>
                            )
                            :
                            (<>
                                {topBadge}
                                {typeof textContent === 'string' ? <Text variant='text' css={styles.textContent} className='title'>{textContent}</Text> : textContent}
                                <div css={styles.numberAndBadgeContainer}>

                                    <div css={styles.numberAndPopperContainer}>
                                        <Text css={styles.numberContent} className='number'>{displayedNumber}</Text>
                                        {popperContent && <PSPopper tooltip={popperContent} wrapperCss={styles.popperWrapperCss} tooltipContainerCss={styles.popper}>
                                            <div/>
                                        </PSPopper>}
                                    </div>

                                    {badge &&
                                        <div css={styles.badgeContainer} className='badgeContainer'>
                                            {badge}
                                        </div>
                                    }
                                </div>
                             </>
                            )
                        }
                    </div>

                </div>

            </div>

            {isLoading &&
                <div css={styles.footerContainer}>
                        <Skeleton variant='text' animation='pulse' height={20} width={140} className='loading-skeleton' css={styles.skeleton}/>
                </div>
            }
            {!isLoading && Boolean(footer) && <div css={styles.footerContainer}>{footer}</div>}
        </div>
    )
}

export default PSOverview;